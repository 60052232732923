import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import {
  StepIndicator,
  StepIndicatorStep,
  ThemeCss,
  Theme,
  StepIndicatorStepProps,
  Heading,
} from "gatsby-interface"
import { deployNow as deployNowText } from "@modules/locales/default.js"
import {
  SiteCreationWizardHeader,
  headingCss,
} from "../../shared/components/SiteCreationWizardHeader"
import { RepositoryStep } from "./RepositoryStep"
import { SetupSiteStep } from "@modules/site/create/shared/components/SetupSiteStep"
import { navigate } from "gatsby"
import { getPathToSiteDetails } from "@modules/site/details/utils"
import { CmsIntegrationsStep } from "@modules/site/create/shared/components/CmsIntegrationsStep"
import { getPathToDeployNowSite } from "@modules/site/create/shared/utils"
import { createSite as text } from "@modules/locales/default.js"
import { CmsVendor } from "@modules/graphql/types"
import SimpleStepIndicator from "../../shared/components/SimpleStepIndicator"
import { useFlags } from "@modules/featureFlags"

const siteRootCss: ThemeCss = theme => ({
  paddingBottom: theme.space[10],
  marginTop: theme.space[8],

  [theme.mediaQueries.phablet]: {
    paddingBottom: "7.5rem",
  },
})

enum DeployNowWizardStep {
  Repository = `repository`,
  Integrations = `integrations`,
  Setup = `setup`,
}

const stepLabels: Record<DeployNowWizardStep, string> = {
  [DeployNowWizardStep.Repository]: deployNowText.labels.repositoryStep,
  [DeployNowWizardStep.Integrations]: deployNowText.labels.integrationsStep,
  [DeployNowWizardStep.Setup]: deployNowText.labels.setupStep,
}

export type DeployNowWizardProps = RouteComponentProps<{
  organizationId?: string
  siteId?: string
  frameable?: boolean
  frameableCmsVendor?: CmsVendor
}>

type StepInfo = {
  step: DeployNowWizardStep
  label: string
  status: StepIndicatorStepProps["status"]
}

export function DeployNowWizard({
  organizationId,
  siteId,
  frameable,
  frameableCmsVendor,
  location,
}: DeployNowWizardProps) {
  const { flags } = useFlags()

  const { activeStep, activeStepIndex, steps } = useDeployNowWizardSteps({
    organizationId,
    siteId,
    location,
  })

  const stepsFiltered = steps.filter(step => {
    if (frameable) {
      return ![
        DeployNowWizardStep.Integrations,
        DeployNowWizardStep.Setup,
      ].includes(step.step)
    }
    return step
  })

  return (
    <main css={siteRootCss}>
      {organizationId ? (
        <SiteCreationWizardHeader organizationId={organizationId} />
      ) : (
        <Heading
          css={(theme: Theme) => [headingCss(theme), { textAlign: "center" }]}
        >
          {text.headers.wizardTitle}
        </Heading>
      )}

      {/* START EXPERIMENT */}
      {flags.simplifiedSiteStepIndicator ? (
        <SimpleStepIndicator
          activeStepNumber={activeStepIndex + 1}
          totalNumberOfSteps={stepsFiltered.length}
        />
      ) : (
        <StepIndicator>
          {stepsFiltered.map(step => (
            <StepIndicatorStep key={step.step} status={step.status}>
              {step.label}
            </StepIndicatorStep>
          ))}
        </StepIndicator>
      )}
      {/* END EXPERIMENT */}
      {activeStep === DeployNowWizardStep.Repository && (
        <RepositoryStep
          location={location}
          frameable={frameable}
          frameableCmsVendor={frameableCmsVendor}
        />
      )}
      {activeStep === DeployNowWizardStep.Integrations &&
        organizationId &&
        siteId && (
          <CmsIntegrationsStep
            siteId={siteId}
            orgSitePath={getPathToDeployNowSite(organizationId)}
          />
        )}

      {activeStep === DeployNowWizardStep.Setup && organizationId && siteId && (
        <SetupSiteStep
          organizationId={organizationId}
          siteId={siteId}
          onPublished={() => {
            navigate(
              `${getPathToSiteDetails(siteId, organizationId)}?newsite=true`
            )
          }}
          onGoBack={() => {
            navigate(
              `${getPathToDeployNowSite(organizationId)}/${siteId}/integrations`
            )
          }}
        />
      )}
    </main>
  )
}

function useDeployNowWizardSteps({ siteId, location }: DeployNowWizardProps): {
  activeStep: DeployNowWizardStep
  activeStepIndex: number
  steps: StepInfo[]
} {
  const steps: DeployNowWizardStep[] = [
    DeployNowWizardStep.Repository,
    DeployNowWizardStep.Integrations,
    DeployNowWizardStep.Setup,
  ]
  let activeStep: DeployNowWizardStep
  const isOnIntegrationStep = location?.pathname?.includes(`integrations`)

  if (siteId) {
    activeStep = isOnIntegrationStep
      ? DeployNowWizardStep.Integrations
      : DeployNowWizardStep.Setup
  } else {
    activeStep = DeployNowWizardStep.Repository
  }
  const activeStepIndex = steps.indexOf(activeStep)

  return {
    activeStep,
    activeStepIndex,
    steps: steps.map((step, stepIndex) => {
      return {
        step,
        label: stepLabels[step],
        status:
          activeStep === step
            ? `ACTIVE`
            : stepIndex < activeStepIndex
            ? `DONE`
            : `DEFAULT`,
      }
    }),
  }
}
