import {
  PlatformLimitSummaryName,
  SourceControlProvider,
} from "@modules/graphql/types"

export const OrgTargetTypes = {
  PersonalAccount: `User`,
  AuthorizedOrganization: `Organization`,
}

// This enum captures all variants for which source-control targets they've
// connected:
// - NotConnectedYet hasn't connected anything
// - PersonalUser has only connected their personal GitHub account to Gatsby
// - SingleOrgMember has only connected 1 GitHub org to Gatsby
// - MultipleTargets has connected at least 2 targets (may or may not include
//   the user's personal account)
export const ConnectedSourceTargetVariants = {
  NotConnectedYet: `not-connected-yet`,
  PersonalUser: `personal-user`,
  SingleOrgMember: `single-org-member`,
  MultipleTargets: `multiple-targets`,
}

const ActivelyTrackedLimitsPrimary = [
  PlatformLimitSummaryName.Bandwidth,
  PlatformLimitSummaryName.Requests,
  PlatformLimitSummaryName.Invocations,
]

const ActivelyTrackedLimitsSecondary = [
  PlatformLimitSummaryName.Users,
  PlatformLimitSummaryName.Sites,
  PlatformLimitSummaryName.HostingIntegrations,
  PlatformLimitSummaryName.CustomDomains,
]

const ActivelyTrackedLimitsFall2022 = [
  PlatformLimitSummaryName.TotalSiteRoutes,
  PlatformLimitSummaryName.MonthlyBuildMinutes,
  PlatformLimitSummaryName.ImageCdnRequests,
]

const DeprecatedLimitsFall2022 = [
  PlatformLimitSummaryName.Requests,
  PlatformLimitSummaryName.CustomDomains,
  PlatformLimitSummaryName.HostingIntegrations,
  // temporary only for the Fall 2022 Pricing launch day adds Sites to deprecated to prevent showing wrongly calculated over usage warning
  // after the launch day when the platformLimitSummaries starts to provide proper calculation for Free plan the Sites should be removed
  // https://app.shortcut.com/gatsbyjs/story/55419/force-front-end-to-find-number-of-sites-max-from-plan-and-not-usage-limit
  PlatformLimitSummaryName.Sites,
]

export const ActivelyTrackedLimits = {
  DEFAULT: ActivelyTrackedLimitsPrimary,
  FREE: [...ActivelyTrackedLimitsPrimary, ...ActivelyTrackedLimitsSecondary],
  FALL2022: ActivelyTrackedLimitsFall2022,
  DEPRECATED: DeprecatedLimitsFall2022,
}

export const SelfHostedGitConnectionAppUrls = {
  [SourceControlProvider.GithubEnterprise]: {
    WebhookUrl: `${process.env.GATSBY_WEBHOOK_URL}/hooks/github_enterprise`,
    CallbackUrl: `${process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/github/enterprise_callback`,
    SetupUrl: `${process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/github_app_installation`,
  },
  [SourceControlProvider.GitlabSelfManaged]: {
    CallbackUrl: `${process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/gitlab_self_managed/callback`,
  },
}

export const PlatformLimitLabels = {
  [PlatformLimitSummaryName.Sites]: `sites`,
  [PlatformLimitSummaryName.Users]: `members`,
  [PlatformLimitSummaryName.CustomDomains]: `custom domains`,
  [PlatformLimitSummaryName.HostingIntegrations]: `hosting integrations`,
  [PlatformLimitSummaryName.TotalSiteRoutes]: `max site size`,
  [PlatformLimitSummaryName.MonthlyBuildMinutes]: `build minutes`,
  [PlatformLimitSummaryName.MonthlyBuilds]: `code builds`,
  [PlatformLimitSummaryName.Bandwidth]: `bandwidth`,
  [PlatformLimitSummaryName.Invocations]: `invocations`,
  [PlatformLimitSummaryName.Requests]: `requests`,
  [PlatformLimitSummaryName.ImageCdnRequests]: `image CDN requests`,
}

export const PlatformLimitsOrder = [
  PlatformLimitLabels[PlatformLimitSummaryName.Users],
  PlatformLimitLabels[PlatformLimitSummaryName.Sites],
  PlatformLimitLabels[PlatformLimitSummaryName.TotalSiteRoutes],
  PlatformLimitLabels[PlatformLimitSummaryName.CustomDomains],
  PlatformLimitLabels[PlatformLimitSummaryName.HostingIntegrations],
  PlatformLimitLabels[PlatformLimitSummaryName.MonthlyBuilds],
  PlatformLimitLabels[PlatformLimitSummaryName.MonthlyBuildMinutes],
  PlatformLimitLabels[PlatformLimitSummaryName.Bandwidth],
  PlatformLimitLabels[PlatformLimitSummaryName.Invocations],
  PlatformLimitLabels[PlatformLimitSummaryName.Requests],
  PlatformLimitLabels[PlatformLimitSummaryName.ImageCdnRequests],
]

export const PlatformRenewableLimits = [
  PlatformLimitSummaryName.MonthlyBuilds,
  PlatformLimitSummaryName.Invocations,
  PlatformLimitSummaryName.Bandwidth,
  PlatformLimitSummaryName.Requests,
  PlatformLimitSummaryName.ImageCdnRequests,
  PlatformLimitSummaryName.MonthlyBuildMinutes,
]
